<template>
  <div 
    class="member-risk-pwd page__member-riskpwd" 
    :class="{ 'member-risk-pwd-dialog': isDialog }"
  >
    <div class="risk-page">
      <div class="risk-top-title">
        {{ language.SHEIN_KEY_PC_17794 }}
        <span 
          v-if="!isDialog"
          class="back-btn" 
          @click="goBack" 
        >
          <Icon 
            class="icon-style"
            name="sui_icon_more_left_18px" 
            size="12px"
            height="16px"
            :is-rotate="GB_cssRight"
          />
          {{ language.SHEIN_KEY_PC_15077 }}
        </span>
      </div>
      <s-alert 
        type="warning" 
        :show-icon="true"
      >
        <span
          v-html="
            isNewReveal && scene == 'login_success'
              ? language.SHEIN_KEY_PC_17878
              : language.SHEIN_KEY_PC_17793
          "
        ></span>
      </s-alert>
      <div class="risk-process">
        <span class="process-item active">{{ language.SHEIN_KEY_PC_17796 }}</span>
        <!---->
        <span class="process-line active">></span>
        <!---->
        <span
          class="process-item"
          :class="{ active: bcIndex > 0 }"
        >{{ language.SHEIN_KEY_PC_16342 }}
        </span>
        <!---->
        <span
          class="process-line"
          :class="{ active: bcIndex > 0 }"
        >></span>
        <!---->
        <span
          class="process-item"
          :class="{ active: bcIndex == 2 }"
        >
          {{ language.SHEIN_KEY_PC_17797 }}
        </span>
        <!-- 用于防止浏览器自动填充 -->
        <input
          class="she-hide"
          type="password"
        />
      </div>

      <div class="pwd-inner">
        <div
          v-show="bcIndex == 0"
          class="process-tab info-tab"
        >
          <div
            v-if="emailCap"
            class="info-item"
          >
            <div class="item-title">
              {{ language.SHEIN_KEY_PC_17798 }}
            </div>
            <s-input
              v-model="showEmail"
              disabled
            />
            <!-- 邮箱发送验证码按钮 -->
            <div class="send-code">
              <!--<input class="j-auth-code" placeholder="" type="text" v-model="sendCodeVal" :autocomplete="'verification-code'"/> <span class="j-send-authcode" :class="{ 'send-grey': sended }" @click.prevent="codeVerify(60)"> {{ sendtips }} </span> -->
            </div>
          </div>

          <div
            v-else
            class="info-item"
          >
            <div class="item-title">
              {{ language.SHEIN_KEY_PC_17799 }}
            </div>
            <s-select
              v-model="telStr"
              :inputable="false"
              :default-select-index="defaultPhoneIndex"
              @change="phoneChange"
            >
              <s-select-option
                v-for="(item, index) in phoneList"
                :key="index"
                :model-value="'' + index"
                :label="item.telephone"
              />
            </s-select>
          </div>

          <!-- 手机发送验证码按钮 -->
          <div class="info-item info-code">
            <div class="item-title">
              {{ language.SHEIN_KEY_PC_14882 }}
            </div>
            <div :class="['code-act', resendTipErr ? 'code-err' : '']">
              <div class="send-input">
                <s-input
                  v-model="sendCodeVal"
                  class="item-code"
                  placeholder=""
                  type="text"
                  :autocomplete="'verification-code'"
                />
              </div>
              <div class="send-code-btn">
                <s-button
                  v-if="emailCap"
                  :type="['primary', 'H44PX']"
                  :disabled="sended"
                  @click.prevent="sendSms(true)"
                >
                  {{ sendtips }}
                </s-button>
                <s-button
                  v-else
                  :type="['primary', 'H44PX']"
                  :disabled="telSended"
                  @click.prevent="sendSms()"
                >
                  {{ telSendtips }}
                </s-button>
              </div>
            </div>
          </div>

          <div class="send-code-err">
            {{ resendTipErr }}
          </div>

          <s-button
            v-if="!goVerifing"
            :disabled="!sendCodeVal"
            class="pwd-btn"
            :type="['primary']"
            block
            @click="goVerifyCode"
          >
            {{ language.SHEIN_KEY_PC_15158 }}
          </s-button>
          <s-button
            v-else
            class="pwd-btn"
            :type="['primary']"
            block
            disabled
          >
            <div class="la-ball-pulse la-ball-pulse-white">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </s-button>
        </div>

        <!-- 设置新密码 -->
        <div
          v-show="bcIndex == 1"
          class="process-tab info-tab"
        >
          <div class="info-item">
            <div class="item-title">
              {{ language.SHEIN_KEY_PC_15017 }}
            </div>
            <s-input
              ref="newPassforce"
              v-model="sendnewpassVal"
              class="j-safe-new-pass"
              :type="inputPwType ? 'password' : 'text'"
              @focus="foucsCheckexg"
              @input="delayCheckexg('sendnewpassVal')"
            />
            <Icon 
              v-show="inputPwType"
              class="pwtype-icon"
              name="sui_icon_eyenormal_16px" 
              size="16px"
              color="#999"
              @click="inputPwType = !inputPwType"
            />
            <Icon 
              v-show="!inputPwType"
              class="pwtype-icon"
              name="sui_icon_eyehover_16px" 
              size="16px"
              color="#999"
              @click="inputPwType = !inputPwType"
            />
            <div
              v-show="!safety.hideAll"
              class="normal-info"
            >
              <div :class="{ 'normal-info-error': safety.showAll }">
                <p :class="{ 'tips-greenp': safety.showFir }">
                  · {{ language.SHEIN_KEY_PC_17274 }}
                </p>
                <p :class="{ 'tips-greenp': safety.showSec }">
                  · {{ language.SHEIN_KEY_PC_17275 }}
                </p>
                <p :class="{ 'tips-greenp': safety.showThi }">
                  · {{ language.SHEIN_KEY_PC_17276 }}
                </p>
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              {{ language.SHEIN_KEY_PC_15677 }}
            </div>
            <s-input
              ref="newPassconforce"
              v-model="sendconpassVal"
              class="j-safe-new-con-pass"
              :type="inputPwType ? 'password' : 'text'"
              @input="filterSpacePwd('sendconpassVal')"
            />
            <Icon 
              v-show="inputPwType"
              class="pwtype-icon"
              name="sui_icon_eyenormal_16px" 
              size="16px"
              color="#999"
              @click="inputPwType = !inputPwType"
            />
            <Icon 
              v-show="!inputPwType"
              class="pwtype-icon"
              name="sui_icon_eyehover_16px" 
              size="16px"
              color="#999"
              @click="inputPwType = !inputPwType"
            />
            <div class="input-error-txt">
              {{ passwordText }}
            </div>
          </div>

          <p
            v-show="sendconPassTxtErr"
            class="normal-info normal-info-error"
            v-html="sendconPassTxtErr"
          ></p>

          <s-button
            v-show="!sendcoding"
            class="pwd-btn"
            :type="['primary']"
            @click.prevent="sendcodePassword"
          >
            {{ language.SHEIN_KEY_PC_14905 }}
          </s-button>
          <s-button
            v-show="sendcoding"
            class="pwd-btn"
            :type="['primary']"
            disabled
          >
            <div class="la-ball-pulse la-ball-pulse-white">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </s-button>
        </div>

        <div
          v-show="bcIndex == 2"
          class="process-tab info-tab success-tab"
        >
          <Icon 
            class="icon-style"
            name="sui_icon_success_96px" 
            size="80px"
            color="#5EBD66"
          />
          <h3>{{ language.SHEIN_KEY_PC_17802 }}</h3>
          <p>{{ language.SHEIN_KEY_PC_17833 }}</p>
          <s-button
            :type="['primary']"
            class="confirm-btn"
            @click.prevent="successConfirm"
          >
            {{ language.SHEIN_KEY_PC_15826 }}
          </s-button>
        </div>

        <!-- 客服 -->
        <a
          v-show="bcIndex != 2"
          href="javascript:;"
          class="pwd-contact"
          @click="onlineHelp"
          v-html="language.SHEIN_KEY_PC_17834"
        ></a>
        <!-- 切换验证方式 -->
        <template
          v-if="
            bcIndex == 0 &&
              riskType == 'choose_number_channel_captcha' &&
              ((emailCap && phoneList.length) || (!emailCap && email))
          "
        >
          <div class="switch-cap">
            <span class="cap-line"></span>
            <span class="cap-tip">or</span>
          </div>
          <div
            class="switch-cap-btn"
            @click="switchCaptcha"
          >
            <Icon 
              v-show="emailCap"
              class="icon-style"
              name="sui_icon_apponly_20px" 
              size="20px"
              color="#000"
            />
            <Icon 
              v-show="!emailCap"
              class="icon-style"
              name="sui_icon_notice_32px" 
              size="20px"
              color="#000"
            />
            {{
              emailCap
                ? language.SHEIN_KEY_PC_17801
                : language.SHEIN_KEY_PC_17800
            }}
          </div>
        </template>
      </div>
      <div
        v-show="sendCodeSucShow"
        class="dialog-send-code-tip"
      >
        {{ language.SHEIN_KEY_PC_17853 }}
      </div>
    </div>
  </div>
</template>
<script>
/* globals _gb_passwordSafetyExp */
import { Icon } from '@shein-aidc/icon-vue3'
import { getQueryString } from '@shein/common-function'
import { SMessage } from '@shein-aidc/sui-message'
import { SAlert } from '@shein-aidc/sui-alert'
import { robotLinkCommon, isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'

const { lang, langPath, login_lang, WEB_CLIENT, GB_cssRight } = gbCommonInfo

export default {
  name: 'RiskConfirmVue',
  components: { SAlert, Icon },
  props: {
    loginType: { type: String, default: '' },
    isAuto: { type: Boolean, default: true },
    //autoCheck: { type: Boolean, default: false },
    isDialog: { type: Boolean, default: false },
  },
  data: function () {
    return {
      WEB_CLIENT,
      GB_cssRight,
      login_lang,
      isDisabled: false,
      showType: '', // 2：显示验证码&修改密码  4:不需要验证弹窗
      site: lang,
      loginError: '',
      loginForm: {
        email: '',
        password: '',
        emailErrorTxt: '',
      },

      sended: false,
      passwordText: '',
      sendnewpassVal: '',
      sendconpassVal: '',
      sendnewpassTxtErr: '',
      sendconPassTxtErr: '',
      resendTipErr: '',
      sendCodeVal: '',
      sendCodestatus: false,
      sendcoding: false,
      sendcodingold: false,
      setTimeId: '',
      setTimePhoneId: '',
      safety: {
        showFir: 0,
        showSec: 0,
        showThi: 0,
        showAll: 0,
        hideAll: 0,
      },
      //是否泄露名单
      //isReveal: isReveal,

      bcIndex: 0,

      scene: 'view_privacy',
      riskStr: 1,

      email: '',
      riskType: '',
      pwdType: '',

      phoneList: [],
      phoneIndex: 0,
      telStr: '',

      riskInfo: {},
      newPwd: '',
      newPwdVis: false,
      conPwd: '',
      conPwdVis: false,
      sendtips: '',
      telSended: false,
      telSendtips: '',

      goVerifing: false,
      emailCap: true,
      tip: {
        show: false,
        txt: '',
      },
      showPhoneList: false,
      goSendPwd: false,

      defaultPhoneIndex: 0,
      language: {},
      isNewReveal: false,
      sendCodeSucShow: false,
      inputPwType: true,
    }
  },
  computed: {
    showEmail() {
      let showEmail = this.email
      if (showEmail) {
        // 获取邮箱后缀
        const suffixArr = showEmail.match(/(?=@).+/)
        let name = showEmail.match(/.*(?=@)/)
        if (suffixArr && suffixArr.length && suffixArr[0]) {
          const suffixStr = suffixArr[0]
          name = name[0] || showEmail
          const _f = name.substr(0, 2)
          const _l = name.substr(2).replace(/./g, '*')
          name = _f + _l
          showEmail = name + suffixStr
        }
      }
      return showEmail
    },
    loginCode() {
      const codeTips = {
        400502: this.language.SHEIN_KEY_PC_14914,
        400505: this.language.SHEIN_KEY_PC_15110,
        400506: this.language.SHEIN_KEY_PC_14477,
        400527: this.language.SHEIN_KEY_PC_17803,
        400528: this.language.SHEIN_KEY_PC_16247,
        400534: this.language.SHEIN_KEY_PC_16194,
        400525: this.language.SHEIN_KEY_PC_17804,
        400535: this.language.SHEIN_KEY_PC_15865,
        400530: this.language.SHEIN_KEY_PC_14885,
        400531: this.language.SHEIN_KEY_PC_17795,
        400524: this.language.SHEIN_KEY_PC_17795,
        9005: this.language.SHEIN_KEY_PC_17803,
        9002: this.language.SHEIN_KEY_PC_17804,
        9007: this.language.SHEIN_KEY_PC_17795,
        default: this.language.SHEIN_KEY_PC_15079,
      }
      return codeTips
    },
  },
  created() {
    if (this.isDialog) {
      schttp({
        url: '/api/user/center/getRiskLang/get',
        method: 'GET',
      }).then((res) => {
        if (res && res.code == 0) {
          const { language = {} } = res.info
          this.updateLang(language)
          this.loginForm.emailErrorTxt = language.SHEIN_KEY_PC_15672
          this.sendtips = language.SHEIN_KEY_PC_14475
          this.telSendtips = language.SHEIN_KEY_PC_14475
        }
      })
    } else {
      document.body.style.background = '#FAFAFA'
      document.body.style.height = '100%'
    }
  },
  mounted: function () {
    window?.sa?.('send', { activity_name: 'expose_reset_password_save' })
    if (!this.isDialog && isLogin()) {
      this.setScene()
      window._GB_DeviceFingerPrint &&
          window._GB_DeviceFingerPrint.callFunc(this.checkInfo)
    }
  },

  methods: {
    sendSms(isEmail) {
      if (isEmail && this.sended) return
      if (!isEmail && this.telSended) return
      let channel = ''
      let target = ''
      let target_key = ''
      let message_type = ''
      const scene = this.scene
      if (isEmail) {
        channel = 'mmp_email'
        target = this.email
      } else {
        channel = 'phone_msg'
        target_key = this.phoneList[this.phoneIndex].billno
        target = this.phoneList[this.phoneIndex].telephone
      }

      scene == 'login_success' && (message_type = 'login_success_confirm')
      scene == 'change_pwd' && (message_type = 'change_pwd_confirm')
      scene == 'choose_wallet' && (message_type = 'choose_wallet_confirm')
      scene == 'view_privacy' && (message_type = 'view_privacy_confirm')
      scene == 'view_wallet_balance' && (message_type = 'view_wallet_confirm')
      scene == 'view_shipping_addr' &&
        (message_type = 'view_shipping_addr_confirm')
      scene == 'place_order' && (message_type = 'place_order_confirm')

      isEmail && (this.sended = true)
      !isEmail && (this.telSended = true)
      this.resendTipErr = ''

      schttp({
        url: '/api/user/center/sendRiskCode/get',
        method: 'POST',
        data: {
          scene,
          risk_id: this.riskInfo.risk_id,
          channel,
          target_key,
          target,
          message_type,
        },
      })
        .then((res) => {
          const { tips } = res
          if (res && res.code == 0 && res.info) {
            const { countdown_second, sended } = res.info
            if (sended == 1) {
              if (this.isDialog) {
                this.sendCodeSucShow = true
                setTimeout(() => {
                  this.sendCodeSucShow = false
                }, 2000)
              } else {
                SMessage({
                  message: this.language.SHEIN_KEY_PC_17853,
                  type: 'success',
                })
              }

              this.countdown(isEmail, 120)
            } else {
              this.countdown(isEmail, countdown_second || 120)
              this.resendTipErr = this.language.SHEIN_KEY_PC_17795
            }
          } else {
            if (isEmail) {
              this.sended = false
              this.sendtips = this.language.SHEIN_KEY_PC_15718
            } else {
              this.telSended = false
              this.telSendtips = this.language.SHEIN_KEY_PC_15718
            }

            this.resendTipErr =
              this.loginCode[res.code] || tips || this.loginCode['default']
          }
        })
        .catch(() => {
          if (isEmail) {
            this.sended = false
            this.sendtips = this.language.SHEIN_KEY_PC_15718
          } else {
            this.telSended = false
            this.telSendtips = this.language.SHEIN_KEY_PC_15718
          }
          this.resendTipErr = this.loginCode['default']
        })
    },

    setType() {
      const { extend_info } = this.riskInfo
      if (extend_info && extend_info.actions) {
        extend_info.actions.forEach((item) => {
          let { name, param } = item

          // 短信验证
          if (name == 'choose_number_captcha') {
            this.riskType = name
            this['phoneList'] = param.telephone_list || []
            this.emailCap = false
            this.sendSms()

            // 邮箱验证
          } else if (name == 'email_captcha' && param.email) {
            this.riskType = name
            this.email = param.email
            this.sendSms(true)

            // 手机/邮箱验证，优先展示邮箱验证
          } else if (name == 'choose_number_channel_captcha') {
            this.riskType = name
            this.email = param.email
            this['phoneList'] = param.telephone_list || []

            if (!this.email && this.phoneList.length) {
              this.emailCap = false
            }
            this.sendSms(true)

            // 爆破黑名单，强制限制访问个人信息
          } else if (name == 'change_pwd_blasting') {
            this.pwdType = name

            // 泄露名单，允许访问个人信息
          } else if (name == 'change_pwd_leak') {
            this.pwdType = name
          }
          this.defaultPhoneIndex = 0
        })
      }
    },

    setScene() {
      // 这里需要传入scene
      let scene = 'view_privacy'
      if (!this.isDialog) {
        scene = getQueryString({ key: 'scene' }) || scene
      }

      // 这里需要传入scene
      // const pathList = location.pathname.split('/')
      // let scene = 'view_privacy'

      // if (pathList.length && pathList[0] === '') {
      //   if (pathList[1] == 'user') {
      //     if (pathList[2] == 'edit_password') {
      //   	scene = 'change_pwd'
      //     }
      //   }
      // }
      this.scene = scene
    },

    updateLang(language) {
      this['language'] = language || {}

      this.sendtips = this.language.SHEIN_KEY_PC_15154
      this.telSendtips = this.language.SHEIN_KEY_PC_15154
    },

    dialogUpdateInfo({ scene, info, cb, force = false } = {}) {
      if (this.isDialog && this.scene != scene) {
        this.bcIndex = 0
      }

      if (force) {
        // 强制清除前面数据
        this.bcIndex = 0
        this.sendCodeVal = ''
        this.sendnewpassVal = ''
        this.sendconpassVal = ''
      }

      this.scene = scene
      let riskStr = this.updateRiskStr(info)
      if ([2, 3].includes(riskStr)) {
        this.setType()
        sa('send', {
          activity_name: 'expose_popup_blacklist_pwreset',
          activity_param: {
            page_from: scene,
          },
        })
      }
      cb && cb(riskStr)
    },

    updateRiskStr(info) {
      let riskStr = 1
      this['riskInfo'] = info
      if (
        info &&
        info.extend_info &&
        info.extend_info.actions &&
        info.extend_info.actions.length
      ) {
        // 泄露名单
        info.extend_info.actions.some(
          (item) => item.name == 'change_pwd_leak'
        ) && (riskStr = 3)
        // 爆破黑名单
        info.extend_info.actions.some(
          (item) => item.name == 'change_pwd_blasting'
        ) && (riskStr = 2)
      }

      // 新泄露名单
      if (
        info &&
        info.risk_reason &&
        info.risk_reason.risk_module_detail &&
        info.risk_reason.risk_module_detail.risk_type &&
        info.risk_reason.risk_module_detail.risk_type.length &&
        info.risk_reason.risk_module_detail.risk_type.includes(444)
      ) {
        this.isNewReveal = true
      }

      this.riskStr = riskStr
      return riskStr
    },

    checkInfo() {
      let data = { scene: this.scene }
      schttp({
        url: '/api/user/center/checkRisk/create',
        method: 'POST',
        data,
      })
        .then((res) => {
          this.updateLang(res.language)

          // 1：非风险用户，2：风险用户（爆破黑名单），3：风险用户（泄露名单）
          let riskStr = 1

          if (res && res.code == 0 && res.info) {
            riskStr = this.updateRiskStr(res.info)
            // 泄露名单展示，爆破黑名单只在登录成功后展示
            if ([2, 3].includes(riskStr)) {
              this.setType()
            }
          }

          if (riskStr == 1 && !this.isDialog) {
            location.href = langPath || '/'
            return
          }

          this.initSaPageInfo()
        })
        .catch(() => {
          if (!this.isDialog) {
            location.href = langPath || '/'
          }
        })
    },

    htmlDecodeByRegExp(str) {
      var s = ''
      if (str.length == 0) return ''
      s = str.replace(/&amp;/g, '&')
      s = s.replace(/&lt;/g, '<')
      s = s.replace(/&gt;/g, '>')
      s = s.replace(/&nbsp;/g, ' ')
      s = s.replace(/&#39;/g, '\'')
      s = s.replace(/&quot;/g, '"')
      s = s.replace(/&#34;/g, '"')
      return s
    },
    checkexg: function (pwd) {
      var self = this
      // var pwdexp = /^(?=.*[0-9])(?=.*[a-zA-Z])/
      // if (pwd == 'password') {
      //     self[pwd] = self.signupForm[pwd].replace(/\s+/g, '')
      // } else {
      self[pwd] = self[pwd].replace(/\s+/g, '')
      // }

      if (self[pwd].length >= 8) {
        self.safety.showFir = 1
      } else {
        self.safety.showFir = 0
      }
      if (/[a-zA-Z]{1,}/.test(self[pwd])) {
        self.safety.showSec = 1
      } else {
        self.safety.showSec = 0
      }
      if (/[0-9]{1,}/.test(self[pwd])) {
        self.safety.showThi = 1
      } else {
        self.safety.showThi = 0
      }
      if (!_gb_passwordSafetyExp.test(self[pwd])) {
        self.safety.showAll = 1
      }
      var timer = setInterval(function () {
        clearInterval(timer)
        if (
          self.safety.showFir == 1 &&
          self.safety.showSec == 1 &&
          self.safety.showThi == 1
        ) {
          self.safety.hideAll = 1

          sa('send', {
            activity_name: 'expose_verification_check_error',
            activity_param: {
              verification_popup_type: 3,
            },
          })
        } else {
          self.safety.hideAll = 0
        }
      }, 200)
    },
    foucsCheckexg: function () {
      this.safety.showAll = 1
    },
    delayCheckexg: function (pwdval) {
      var self = this
      self.checkexg(pwdval)
    },
    filterSpacePwd: function (value) {
      var self = this
      self[value] = self[value].replace(/\s+/g, '')
    },
    successHandle() {
      this.bcIndex = 2
      this.$emit('verify-suc-callback', { scene: this.scene })

      // 重置状态
      this.sended = false
      this.telSended = false
      this.sendtips = this.language.SHEIN_KEY_PC_15154
      this.telSendtips = this.language.SHEIN_KEY_PC_15154

      try {
        localStorage.removeItem('user_need_edit_pwd')
      } catch (e) {
        // todo: 异常抛出
      }
    },
    sendcodePassword: function () {
      window?.sa?.('send', { activity_name: 'click_reset_password_save' })
      this.sendconPassTxtErr = ''

      if (
        this.sendnewpassVal === '' ||
        !_gb_passwordSafetyExp.test(this.sendnewpassVal)
      ) {
        this.safety.hideAll = 0
        return
      }

      if (this.sendnewpassVal != this.sendconpassVal) {
        this.sendconPassTxtErr = this.loginCode['400502']
        return
      }

      this.sendcoding = true
      this.passwordText = ''

      var data = {
        risk_id: this.riskInfo.risk_id,
        content: this.sendCodeVal,
        password: this.sendnewpassVal,
        password_confirm: this.sendconpassVal,
        scene: this.scene,
      }

      schttp({
        url: '/api/user/center/riskPwdChange/create',
        method: 'POST',
        data,
      }).then((res) => {
        const { tips } = res
        this.sendcoding = false

        //401101是后端熔断，放行
        if (
          (res.code == 0 && res.info && res.info.result == 1) ||
          res.code == 401101
        ) {
          this.successHandle()
          return
        }
        this.sendconPassTxtErr =
          this.loginCode[res.code] || tips || this.loginCode['default']
      })

      sa('send', { activity_name: 'click_submit' })
    },

    countdown(isEmail, time) {
      let s = 0
      let txt = ''

      isEmail && clearInterval(this.setTimeId)
      !isEmail && clearInterval(this.setTimePhoneId)

      let _timer = setInterval(() => {
        if (time <= 0) {
          if (isEmail) {
            this.sended = false
            this.sendtips = this.language.SHEIN_KEY_PC_14475
          } else {
            this.telSended = false
            this.telSendtips = this.language.SHEIN_KEY_PC_14475
          }

          // this.sendCodestatus = false
          isEmail && clearInterval(this.setTimeId)
          !isEmail && clearInterval(this.setTimePhoneId)
          return
        }
        time--
        // this.sendCodestatus = true

        let h = 0
        let timer = time
        if (time > 3600) {
          h = parseInt(time / 3500)
          timer = time % 3600
        }

        if (timer > 60) {
          s = timer % 60
          txt =
            parseInt(timer / 60) + ' : ' + (s ? (s < 10 ? '0' + s : s) : '00')
        } else {
          txt = timer + 's'
        }

        h && (txt = h + ' : ' + txt)

        if (isEmail) {
          this.sended = true
          this.sendtips = txt
        } else {
          this.telSended = true
          this.telSendtips = txt
        }
      }, 1000)

      if (isEmail) {
        this.setTimeId = _timer
      } else {
        this.setTimePhoneId = _timer
      }
    },
    onlineHelp: function () {
      location.href = robotLinkCommon()

      sa(
        'send',
        {
          activity_name: 'click_customer_service',
        },
        { beacon: 1 }
      )
    },
    goVerifyCode() {
      if (this.sendCodeVal.trim() == '') {
        this.resendTipErr = this.language.SHEIN_KEY_PC_14473
        return false
      } else {
        this.resendTipErr = ''
      }

      if (this.goVerifing) return
      this.goVerifing = true
      schttp({
        url: '/api/user/center/verifyRiskCode/get',
        method: 'POST',
        data: {
          risk_id: this.riskInfo.risk_id,
          content: this.sendCodeVal,
        },
      })
        .then((res) => {
          if (res && (res.code == 0 || res.code == 401101)) {
            this.bcIndex = 1
          } else if (res.code == 9006) {
            // 风控单已闭环，可直接跳到成功
            this.successHandle()
          } else {
            this.resendTipErr =
              this.loginCode[res.code] || this.loginCode['default']
            // self.sendconPassTxtErr = this.loginCode[res.code] || this.loginCode['default']

            if (['400502', '400506'].indexOf(res.code) >= 0) {
              // todo: 异常码处理
            } else if (['400534', '400525'].indexOf(res.code) >= 0) {
              // todo: 异常码处理
            } else {
              // todo: 异常码处理
            }
          }

          this.goVerifing = false
        })
        .catch(() => {
          this.goVerifing = false
        })

      sa('send', { activity_name: 'click_next' })
    },
    phoneChange(val) {
      if (this.phoneIndex != val) {
        val = +val
        this.phoneIndex = val
        this.sendSms()
        this.sendCodeVal = ''
      }
    },
    switchCaptcha() {
      this.emailCap = !this.emailCap
      this.sendSms(this.emailCap)

      sa('send', { activity_name: 'click_switch_method' })
    },
    goBack() {
      location.href = langPath || '/'
    },
    successConfirm() {
      if (this.isDialog) {
        // 下单页的弹窗隐藏
        this.$emit('confirm-callback')
      } else {
        let url = langPath || '/'
        if (getQueryString({ key: 'redirection' })) {
          url = getQueryString({ key: 'redirection' })
        }
        location.href = url
      }
    },
    initSaPageInfo() {
      if (this.isDialog) return
      //页面信息
      var SaPageInfo = {
        page_id: 210,
        page_name: 'page_blacklist_pwreset',
        page_param: {
          page_from: this.scene,
        },
        start_time: new Date().getTime(),
      }

      //进入页面
      sa('set', 'setPageData', SaPageInfo)
      sa('send', 'pageEnter', {
        start_time: new Date().getTime(),
      })
      // sa('send', { 'activity_name': 'expose_popup_blacklist_pwreset' });

    },
  },
  emits: ['verify-suc-callback', 'confirm-callback'],
}
</script>

<style lang="less">
/* stylelint-disable*/
.page__member-riskpwd {
	background: #FAFAFA;
	color: #222;
	.risk-page {
		width: 800px;
		height: 600px;
		background: #fff;
		margin: 0 auto;
		margin-top: 30px;
		text-align: center;
		position: relative;
		overflow: auto;
		// border: 1px solid #979797;
		.back-btn {
			position: absolute;
			top: 30px;
			font-size: 12px;
			.left(16px);
			line-height: 30px;
			cursor: pointer;
      display: flex;
      align-items: center;

			.icon-left-arrow {
				display: inline-block;
				transform: scale(0.6);
			}
		}

		.risk-top-title {
			height: 30px;
			line-height: 30px;
			font-size: 20px;
			margin: 30px 0 20px 0;
			text-transform: uppercase;
			.icon-style {
				width: 16px;
				line-height: 16px;
				.margin-r(6px);
				display: inline-block;
			}
		}
		.risk-top-tip {
			width: 100%;
			height: 60px;
			font-size: 14px;
			background: #FFF7E7;
			display: flex;
			align-items: center;
			.padding-l(71px);
			.txt-l();

			span {
				display: inline-block;
			}
		}
		.risk-process {
			font-size: 16px;
			line-height: 18px;
			margin-top: 20px;
			color: #ccc;
			.active {
				color: #222;
			}
			.process-line {
				margin: 0 15px;
			}
		}
		.pwd-inner {
			width: 320px;
			margin: 0 auto;
			.txt-l();
			.process-tab {
				.send-code-err {
					font-size: 12px;
					color: #FF411C;
					line-height: 14px;
					margin: 4px 0 22px 0;
				}
				.info-item {
					position: relative;
					.item-title {
						font-size: 13px;
						line-height: 15px;
						color: #999;
						margin-bottom: 8px;
						margin-top: 20px;
					}
					.code-act {
						border: 1px solid #E5E5E5;
            display: flex;
						input {
							border: none;
							vertical-align: top;
						}
            .send-input {
                flex: 1 1 100%;
            }
					}
					.code-err {
						border: 1px solid #FF411C;
						input {
							background: #fff3f1;
						}
					}
					.item-code {
						width: 100%;
						vertical-align: middle;
					}
					.send-code-btn {
						min-width: auto;
						height: 40px;
						line-height: 40px;
						color: #fff;
            border-radius: 0;
						flex: 0 0 100px;
            position: relative;
            top: -1px;
            .right(-1px);
            .sui-button-common {
              min-width: auto;
              width: auto;
              border-radius: 0;
              width: 100%;
              font-size: 14px;
              padding: 0 10px;
              height: 42px;
              line-height: 42px;
            }
					}
					.disabled {
						background: #C1C1C1;
					}
					.sui-select {
						width: 100%;
					}
					.pwtype-icon {
						line-height: 16px;
						position: absolute;
						font-size: 16px;
						top: 35px;
						color: #999;
						.right(12px);
					}
				}
				.info-code {
					input {
						vertical-align: middle;
					}
				}
			}
		}
		.pwd-btn {
			width: 100%;
			margin-top: 40px;
		}
		.normal-info {
            color: #999;
            margin-top: 10px;
			.tips-greenp {
				color: #5EBD66;
			}
		}
		.normal-info-error {
            color: #d53333;
		}
		.pwd-contact {
			margin-top: 10px;
			text-align: center;
			display: block;
		}
		.switch-cap {
			color: #E5E5E5;
			text-align: center;
			position: relative;
			margin: 37px 0 15px 0;
			.cap-line {
				width: 100%;
				display: inline-block;
				height: 1px;
				background: #E5E5E5;
				vertical-align: middle;
				position: absolute;
				top: 6px;
				.left(0);
				z-index: @zindex-zero;
			}
			.cap-tip {
				height: 14px;
				line-height: 14px;
				padding: 0 10px;
				background: #fff;
				display: inline-block;
				position: relative;
				z-index: @zindex-hack;
			}
		}
		.switch-cap-btn {
			height: 40px;
			color: #666;
			border: 1px solid #E5E5E5;
			text-align: center;
			line-height: 40px;
			cursor: pointer;
			box-sizing: border-box;
			position: relative;

      .icon-style{
				position: absolute;
				line-height: 40px;
				font-size: 20px;
				color: #000;
				top: 10px;
				.left(15px);
			}
		}
	}
	.success-tab {
		text-align: center;
		.icon-style {
			height: 80px;
			line-height: 80px;
			margin-top: 62px;
			font-size: 80px;
			margin-bottom: 18px;
			display: block;
			color: #5EBD66;
      & when (@IS_RW) {
				color: @sui_color_main;
			}
		}
		h3 {
			font-size: 16px;
			line-height: 20px;
			color: #222;
			margin-bottom: 5px;
		}
		p {
			line-height: 14px;
			color: #999;
		}
		.confirm-btn {
			width: 100%;
			display: block;
			margin-top: 60px;
			text-transform: uppercase;
		}
	}
	.dialog-send-code-tip {
		height: 44px;
		line-height: 44px;
		position: absolute;
		top: 260px;
		color: #fff;
		background: #000;
		border-radius: 5px;
		padding: 0 16px;
		opacity: 0.8;
		transform: translate(-50%);
		.left(50%);
	}
  &.member-risk-pwd-dialog {
		.risk-page {
			width: 100%;
			margin-top: 0;
			font-size: 12px;
		}
	}
}
</style>
